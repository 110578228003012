import styled from 'styled-components';

export const Content = styled.div`
    width: 500px;

    h1 {
        margin-bottom: 30px;
    }

    #forgotPassword {
        text-align: right;
        button {
            padding: 5px 0;
        }
    }

    @media (max-width: 1024px) {
      width: 80%;
    }
`;