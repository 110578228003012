import styled from 'styled-components';

export const Page = styled.div`
  
`;

export const Sidebar = styled.div`
    height: 100vh;
    width: 400px;
    display: inline-block;
    vertical-align: top;

    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
`;

export const SidebarContent = styled.div`
    height: calc(100vh - 100px);
    width: 100%;
    background-color: #EE504F;
    padding: 50px 25px 0;
    color: white;

    h1 {
        margin-bottom: 25px;
    }

    @media (max-width: 768px) {
      height: auto;
      padding: 25px;
    }
`;

const SidebarHelpWrapper = styled.div`
    height: 100px;
    width: 100%;
    background-color: #c52127;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
`;

const SidebarHelpLinks = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 25px;

    a, span {
        color: white;
    }

    a:hover {
        color: white;
    }

    span {
        font-size: 12px;
        margin: 0 2px;
        transform: translateY(-2px);
    }
`;

const SidebarHelpLeft = styled.div`
    align-self: flex-start;
`;

const SidebarHelpRight = styled.div`
    align-self: flex-end;
`;

export const SidebarHelp = {
    Wrapper: SidebarHelpWrapper,
    Links: SidebarHelpLinks,
    Left: SidebarHelpLeft,
    Right: SidebarHelpRight,
};

export const Layout = styled.div`
  width: calc(100% - 400px);
  display: inline-block;
  background-color: #fff;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
    padding: 50px 0 100px;
  }
`;

export const Content = styled.div`
    width: 500px;

    h1 {
        margin-bottom: 30px;
    }

    #forgotPassword {
        text-align: right;
        button {
            padding: 5px 0;
        }
    }

    @media (max-width: 1024px) {
      width: 80%;
    }
`;
